.container-apropos {
  display: flex;
  justify-content: center;
  padding: 20px 0 20px 0;
  background-color: #111;
  width: 1896px;
  max-width: 100%;
}

.outer-border {
  width: 1896px;
  max-width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: inset 0px 0px 10px rgb(255, 255, 255);
}
.container-profile {
  width: 1600px;
  margin: auto !important;
}
.title-section {
  text-align: center;
  margin-bottom: 20px;
}

.title-section h2 {
  color: #d41516;
  font-family: oswald, sans-serif;
  font-size: 40px;
}

.name-section {
  text-align: center;
  margin-bottom: 20px;
}

.name-section h3 {
  color: #f39c12;
  padding-top: 30px;
  padding-bottom: 30px;
}

.name-section {
  border-radius: 10px;
  box-shadow: inset 0px 0px 10px rgb(255, 255, 255);
}

.profile-card {
  margin-bottom: 40px;
}

.profile-card img {
  margin-bottom: 20px;
}
.profile-card h1 {
  color: #d41516;
  text-align: center;
  margin-bottom: 50px;
  font-family: Segoe Script, sans-serif;
  font-size: 40px;
  margin-top: 60px;
}
.profile-card p {
  color: #fff;
  font-family: roboto, sans-serif;
  font-size: 24px;
  padding-left: 20px;
  padding-right: 20px;
}
.text-profile {
  border-radius: 10px;
  box-shadow: inset 0px 0px 10px rgb(255, 255, 255);
}
@media (max-width: 768px) {
  .container-profile {
    width: auto;
  }
  .title-section h2 {
    font-size: 15px;
  }
  .profile-card h1 {
    font-size: 15px;
    margin: 15px 0;
    padding-left: 20px;
    padding-right: 20px;
    text-align: start;
  }
  .profile-card p {
    font-size: 12px;
  }
  .outer-border{
    box-shadow: none;
  }
}
