.movie-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .movie-card-cat {
    position: relative;
    flex: 0 1 calc(13.28% - 30px); /* 100% / 7 films - marge */
    margin: 45px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 16px 12px 17px rgb(160, 160, 159);
    transition: transform 0.2s;
    width: 100px;
  }
  
  .movie-card-cat::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }
  
  .movie-card-cat img {
    width: 100% !important;
    height: 330px !important;
    display: block;
  }
  
  .movie-card-cat:hover {
    transform: scale(1.05);
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  @media (max-width: 768px) {
    .movie-card-cat  {
      box-shadow: 5px 5px 10px rgb(160, 160, 159);
      flex: none;
      margin: 4px;
      width: 121px;
      height: 180px;
    }
    .container{
      padding: 10px !important;
    }
  }
  