.search {
  .search-section {
    margin-top: 10px;
    border-radius: 10px;
    background-image: url(./../../assets/imgfond.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 50px 0;
  }
  .search-container {
    text-align: center;
  }
  .search-box {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
  }
  .search-box input {
    width: 100%;
    padding: 15px;
    border-radius: 25px;
  }
  p {
    font-size: 36px;
    background-color: rgba(0,0,0,0.7); 
    color: white;
    padding: 10px 10px;
    display: inline-block; 
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    .search-section {
      width: 100%;
    }
  
    .search-container {
      width: 100%;
    }
  
    .search-box {
      width: 80%;
    }
  
    .form-control {
      width: 100%;
    }
    p{
      font-size: 11px;
    }
  }
}
