@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
$font-1: "Poppins", sans-serif;
$color-1: #61dafb;
$color-2: #f7f7f7;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
* {
  box-sizing: border-box;
  padding: 0;
}
body {
  width: 1896px;
  margin: 12px auto 12px auto !important;
  background-color: #000000 !important;
  font-family: $font-1;
  color: $color-3;
}
li {
  list-style-type: none;
}
.carousel-indicators {
  display: none !important;
}
.carousel-control-prev,
.carousel-control-next {
  width: 5% !important;
}
nav {
  border-radius: 10px;
}
@media (max-width: 768px) {
  body {
    padding: 10px;
    width: auto;
    margin: 12px auto 12px auto !important;
  }
  .container {
    padding: 10px;
    max-width: 100%;
  }


}
@media (max-width: 1919px) {
  body {
    padding: 10px;
    width: auto;
    margin: 12px auto 12px auto !important;
  }
 .container-profile{
  width: auto !important;
 }


}
