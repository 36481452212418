.body {
  background-color: black;
  .carousel-inner {
   
    .carousel-inner {
      height: 40rem;
    }
    .carousel-caption {
      position: absolute;
      text-align: left;
    }
    .carousel-item img {
      width: 100%;
      height: 700px;
    }
    
    * {
      box-sizing: border-box;
    }
   
  }
  .tt-centre{
    text-align: center;
    color: white;
  }

@media (max-width: 768px) {
  .custom-container {
    padding: 10px;
    max-width: 100%;
  }

  .carousel-inner {
    height: auto;
  }

  .carousel-item img {
    height: auto;
  }

  .movie-card {
    margin-bottom: 10px;
  }

  .movie-card img {
    width: 100%;
  }
}
}
